<template>
  <main class="page document-page" v-if="document">
    <BreadcrumbsComponent
      :title="document.title"
      :links="[{ title: 'Документы', to: { name: 'documents' } }]"
    />
    <div class="container-padding">
      <div class="document-page__content">
        <aside class="document-aside">
          <div class="document-aside__item" v-if="document.document_type">
            <span class="document-aside__item-title">Тип:</span>
            <span class="document-aside__item-content">{{ document.document_type.title }}</span>
          </div>
          <div class="document-aside__item" v-if="document.accepted_at">
            <span class="document-aside__item-title">Документ от:</span>
            <span class="document-aside__item-content">
              {{ document.accepted_at | humanDate }}
            </span>
          </div>
          <div class="document-aside__item" v-if="document && document.file">
            <a
              :href="document.file?.path"
              class="btn btn--md btn--main document-aside__btn"
              :download="document.file.origin_name"
            >
              <IconComponent name="receive-square" />
              <span>
                Скачать
                <template v-if="document.file.type && document.file.size">
                  ({{ document.file.type }}, {{ document.file.size }})
                </template>
              </span>
            </a>
          </div>
          <!--          <div class="document-aside__item">-->
          <!--            <span class="document-aside__item-title">Поделиться:</span>-->
          <!--            <div class="document-aside__item-socials">-->
          <!--              <ShareNetwork-->
          <!--                :url="$store.state._env.MEDIA_ENDPOINT + document.file.path"-->
          <!--                network="telegram"-->
          <!--                :title="document.title"-->
          <!--                :description="document.file.origin_name"-->
          <!--              >-->
          <!--                <IconComponent category="default" name="tg" />-->
          <!--              </ShareNetwork>-->
          <!--              <ShareNetwork-->
          <!--                :url="$store.state._env.MEDIA_ENDPOINT + document.file.path"-->
          <!--                network="whatsapp"-->
          <!--                :title="document.title"-->
          <!--                :description="document.file.origin_name"-->
          <!--              >-->
          <!--                <IconComponent category="default" name="wp" />-->
          <!--              </ShareNetwork>-->
          <!--              <ShareNetwork-->
          <!--                :url="$store.state._env.MEDIA_ENDPOINT + document.file.path"-->
          <!--                network="vk"-->
          <!--                :title="document.title"-->
          <!--                :description="document.file.origin_name"-->
          <!--              >-->
          <!--                <IconComponent category="default" name="vk" />-->
          <!--              </ShareNetwork>-->
          <!--            </div>-->
          <!--          </div>-->
        </aside>
        <iframe
          v-if="document.file"
          :src="`https://docs.google.com/gview?url=${
            $store.state._env.MEDIA_ENDPOINT + document.file.path
          }&embedded=true`"
          class="document-page__iframe"
        />
        <p v-else>В данный момент документ не загружен</p>
      </div>
    </div>
  </main>
  <NotFound v-else />
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import IconComponent from "components/IconComponent.vue";
import DOCUMENTS_OPEN_PAGE from "gql/pages/DocumentsOpenPage.graphql";
import NotFound from "@/views/PageNotFound.vue";

export default {
  name: "DocumentsOpenPage",
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: DOCUMENTS_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("documents/save", data);
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  computed: {
    document() {
      return this.$store.state.documents.acts_item;
    },
  },
  metaInfo() {
    const meta = this.$store.state.documents.acts_item;
    return {
      title: meta?.title,
    };
  },
  components: { NotFound, IconComponent, BreadcrumbsComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/elements/doc-aside.styl"
.document-page {
  display grid
  grid-gap 50px
  margin-bottom 50px
  +below(1024px) {
    grid-gap 30px
    margin-bottom 30px
  }

  &__container {
    display: flex;
    justify-content: center;
    padding: 0 30px;
    width: 100%;
  }

  &__content {
    max-width: var(--max-width);
    width: 100%;
    display: grid;
    grid-gap 30px
    align-items start
    grid-template-columns 328px 1fr
    +below(1024px) {
      grid-template-columns 1fr
    }
  }

  &__iframe {
    border none
    height 100vh
    width 100%
    border-radius 10px
    +below(1024px) {
      height 100vw
    }
  }
}
</style>
